import React, { useMemo } from "react";
import { VoiceListWithFilter } from "~components/assessment/voice-list-with-filter";
import { withContext } from "~context";

import * as styles from "./index.module.scss";
import { graphql } from "gatsby";
import { PageLayout } from "~components/templates/page-layout";

type Props = {
  data: GatsbyIndustryVoicePageComponentQuery;
};

const Component = ({ data }: Props) => {
  const voiceList = useMemo(() => {
    return data.allContentfulVoiceIndustry.nodes[0].voice?.map((voice) => {
      return {
        ...voice,
        link: `/assessment/voice/${voice?.slug}`,
      };
    });
  }, [data]);

  const IndustryName = data.contentfulVoiceIndustry?.name;

  return (
    <PageLayout
      paddingPattern="fit-header"
      meta={{
        title: `${IndustryName}業界のセキュリティ診断事例インタビュー`,
      }}
    >
      <main className={styles.AssessmentVoice_Wrapper}>
        <h1 className={styles.AssessmentVoice_Title}>
          セキュリティ診断事例インタビュー
        </h1>
        <VoiceListWithFilter
          data={voiceList ?? []}
          defaultIndustry={data.contentfulVoiceIndustry?.slug}
        />
      </main>
    </PageLayout>
  );
};

export default withContext(Component);

export const query = graphql`
  query IndustryVoicePageComponent($slug: String!) {
    contentfulVoiceIndustry(slug: { eq: $slug }) {
      slug
      name
    }
    allContentfulVoiceIndustry(filter: { slug: { eq: $slug } }) {
      nodes {
        voice {
          ...VoiceQuery
        }
      }
    }
  }
`;
